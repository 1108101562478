.loginBackground {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, #161122 21px, transparent 1%) center,
    linear-gradient(#161122 21px, transparent 1%) center, #a799cc;
  background-size: 22px 22px;
}

.loginContainer {
  background-color: white;
  padding: 32px 40px 40px;
}

.loginTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 54px;
}

.loginTitleMargin {
  margin-bottom: 14px;
}

.loginMessage {
  display: none;
  color: #fd4444;
  line-height: 28px;
  font-weight: 600;
  font-size: 14px;
  line-height: 28px;
  margin-bottom: 28px;
}

.loginMessageVissible {
  display: block;
  margin-bottom: 14px;
}

.loginContainer label {
  width: 360px;
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 8px;
}

.loginContainer input {
  width: 100%;
  height: 48px;
  background: #ffffff;
  border: 1px solid #ced4da;
  border-radius: 0px;
  margin-bottom: 16px;
}

.loginContainer button {
  width: 100%;
  height: 48px;
  margin-top: 24px;
  color: white;
  background-color: #fd4444;
  border-radius: 0px;
  border-style: none;
}

.loginContainer button:hover {
  background-color: #ff6a44;
}
