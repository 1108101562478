.videoPage {
  background: linear-gradient(180deg, #000000 21px, transparent 10%) center,
  linear-gradient(60deg, #000000 21px, transparent 10%) center,
    linear-gradient(#00FF00 31px, transparent 10%) center, white;
  background-size: 22px 22px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoContainer {
  position: relative;
  flex-direction: column;
}

.videoPlayer {
  width: 960px !important;
  height: 540px !important;
  margin-bottom: 32px;
}

.liveLabel {
  display: none;
  align-items: center;
  padding: 0px 8px;
  text-transform: uppercase;
  position: absolute;
  color: #00FF00;
  top: 20px;
  left: 20px;
  height: 24px;
  font-size: 12px;
}

