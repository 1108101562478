.centerContainer {
  width: 1120px;
  height: 100%;
  margin: 0 auto;
  margin-top: 24px;
}

.scrollbar {
  background: white;
  overflow-y: scroll;
  padding-right: 8px;
}
::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar {
  width: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #6c757d;
}

.listContainer {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.listItem {
  list-style-type: none;
  color: #343a40;
  background-color: white !important;
  border-style: none !important;
  font-size: 14px;
  line-height: 28px;
  height: 44px;
  width: 100%;
  padding: 0px 16px;
}
.listItem:hover {
  border-style: none !important;
  border-radius: 0 !important;
  background-color: #e5e5e5 !important;
  cursor: pointer;
}

.setActive {
  background-color: #e5e5e5 !important;
}

.setListInactive {
  list-style-type: none;
  padding: 0;
  margin: 0;
  pointer-events: none;
}

.setListInactive .listItem {
  color: #abb5be !important;
}

.videoItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.videoName {
  display: inline-block;
  width: calc(100% - 68px);
  height: 100%;
  padding: 8px 0px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 60px;
}

.liveIndicator:before {
  content: '';
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #ff4d20;
  border-radius: 50%;
  margin-right: 8px;
}

.liveIndicator {
  color: white;
  background-color: #212529;
  text-transform: uppercase;
  padding: 4px 8px 4px 6px;
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  margin-left: 16px;
  position: absolute;
  right: 0;
  top: 12px;
}

.iconArea {
  display: flex;
  align-items: center;
}
.linkIcon {
  display: block;
  float: right;
  width: 24px;
  height: 24px;
  margin-left: 12px;
  background-image: url('../../../../../icons/link.svg');
}

.deleteIcon {
  display: none;
  width: 24px;
  height: 24px;
  float: right;
  background-image: url('../../../../../icons/delete.svg');
  cursor: context-menu;
}
