.videoContainer {
  position: relative;
  flex-direction: column;
}

.videoPlayer {
  width: 100% !important;
  height: 100vh !important;
  background-color: black !important;
}

.liveLabel {
  display: none;
  align-items: center;
  padding: 0px 8px;
  text-transform: uppercase;
  position: absolute;
  color: #00ff00;
  top: 20px;
  left: 20px;
  height: 24px;
  font-size: 12px;
}
