.listGroup {
  display: flex !important;
  justify-content: space-between !important;
  border: none !important;
}
/* .userNameBagde {
  display: flex;
} */
.deleteIcon {
  display: none;
  width: 24px;
  height: 24px;
  /* float: right; */
  background-image: url('../../../../../../icons/delete.svg');
  cursor: context-menu;
}

.bagde {
  align-items: center;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  float: left;
  color: white;
  width: 50px;
  height: 18px;
  background-color: #343a40;
  text-transform: uppercase;
  padding: 3px 8px;
  margin-left: 20px;
  margin-top: 3px;
}
